import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ExamService} from "../../services/exam.service";
import {SpinnerService} from "../../services/spinner.service";
import {ExamTableComponent} from "./exam-table/exam-table.component";
import {ExamSearchComponent} from "./exam-search/exam-search.component";
import IExam from "../../models/IExam";
import {ProgramFilterComponent} from "./program-filter/program-filter.component";
import {IProgram} from "../../../../../../ProctorApp2.Logic/Models/i.program";

@Component({
   selector: 'app-exam-selection',
   templateUrl: './exam-selection.component.html',
   styleUrls: ['./exam-selection.component.scss']
})
export class ExamSelectionComponent implements OnInit {
   @ViewChild(ExamTableComponent) examTableComponent = new ExamTableComponent();
   @ViewChild(ExamSearchComponent) examSearchComponent = new ExamSearchComponent();
   @ViewChild(ProgramFilterComponent) programFilterComponent = new ProgramFilterComponent();
   @Input() languageCode = '';
   @Input() examId = '';
   @Output() selectExam = new EventEmitter();
   @Output() selectLanguage = new EventEmitter<string>();
   search = '';
   programId = 0;
   exams: IExam[] = [];
   private programs: IProgram[] = [];
   alertHidden: string = 'hidden';
   message: string = '';
   status: string = '';

   constructor(private examService: ExamService, private spinnerService: SpinnerService) { }

   ngOnInit(): void {
      this.populateEFHExams(this.languageCode);
   }

   onSelectExam(selectedExam: IExam) {
      this.selectExam.emit(selectedExam);
   }

   onExamSearch(searchValue: string) {
      this.search = searchValue;
      this.searchExams();
   }

   changeLanguage(languageCode: string) {
      this.selectLanguage.emit(languageCode);
      this.populateEFHExams(languageCode);
   }

   changeProgram(programId: number){
      this.programId = programId;
      this.searchExams();
   }

   searchExams() {
      this.examTableComponent.searchResults = this.exams;
      if(this.search != ''){
         this.examTableComponent.searchResults = this.examTableComponent.searchResults.filter(x => x.ProductName.toLowerCase().includes(this.search.toLowerCase()));
      }
      if(this.programId != 0){
         this.examTableComponent.searchResults = this.examTableComponent.searchResults.filter(x => x.ProgramID == this.programId);
      }
      this.populateProgramFilter();
   }

   populateEFHExams(languageCode: string) {
      this.spinnerService.show();
      this.examService.getEFHSupportedExams(languageCode)
         .subscribe(x => {
            this.exams = x;
            this.searchExams();
            this.spinnerService.hide();
            if (x == null || x.length === 0) {
               this.GetEfhExamsError();
            }
         }, error => {
            this.GetEfhExamsError();
            this.spinnerService.hide();
         });
   }

   populateProgramFilter() {
      this.programs = [];

      this.exams.filter(exam => {
         let program = {
            programId: exam.ProgramID,
            programName: exam.ProgramName
         } as IProgram;

         if (!this.isProgramInList(program)) {
            this.programs.push(program);
         }
      });
      this.programs.sort((p, q) => p.programName.localeCompare(q.programName));

      this.programFilterComponent.programs = this.programs;
   }

   isProgramInList(newProgram: IProgram) {
      let programIsPresent = false;

      this.programs.forEach(program => {
         if (program.programId === newProgram.programId) {
            programIsPresent = true;
         }
      });
      return programIsPresent;
   }

   private GetEfhExamsError() {
      this.message = $localize`The system failed to provide the list of supported exams.`;
      this.status = 'error';
      this.alertHidden = '';
   }
}
